<template>
  <div class="d-flex flex-column">
    <v-row class="justify-content-center pb-5">
      <a href="#" class="w-auto font-weight-bold text-gray-700 text-decoration-none" @click="updateLocale('fr')">
        <country-flag country="fr" size="small" shadow/>
        <span class="ms-2">FR</span>
      </a>
      <a href="#" class="w-auto font-weight-bold text-gray-700 text-decoration-none" @click="updateLocale('en')">
        <country-flag country="gb" size="small" shadow/>
        <span class="ms-2">EN</span>
      </a>
    </v-row>
    <div class="login-container">

      <div class="internal-login">
        <h1><strong>{{ $t('message.employee_or_former_fiducial') }}</strong></h1>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-alert
                text
                dense
                type="error"
                v-if="sessionTimeout"
              >
                {{ $t('message.session_expired') }}
              </v-alert>

              <v-alert
                text
                dense
                color="teal"
                border="left"
                type="success"
                v-if="successMessage"
              >
                {{ successMessage }}
              </v-alert>

              <v-text-field
                autocomplete="username"
                v-model="email"
                :label="$t('message.label_email')"
                :placeholder="autofill ? ' ' : ''"
              ></v-text-field>

              <v-text-field
                autocomplete="current-password"
                v-model="password"
                :label="$t('message.label_password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :placeholder="autofill ? ' ' : ''"
              ></v-text-field>

              <v-alert
                text
                dense
                type="error"
                v-if="error !== null"
              >
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>

          <router-link to="/mot-de-passe-oublie" class="recovery">{{ $t('message.forgot_password') }}</router-link>

          <button :disabled="disableSubmit" type="submit" class="btn btn-connect notranslate">
            <strong>{{ $t('message.login') }}</strong></button>

          <div class="activation">
            <p>
              <strong>{{ $t('message.not_registered_yet') }}</strong>
            </p>
            <router-link to="/activation/etape-1" tag="button" class="btn green white--text mt-4">
              <strong class="infos">{{ $t('message.account_activation_via_temp') }}</strong>
            </router-link>
          </div>
          <router-link to="/contact" class="internal-contact">{{ $t('message.employee_contact') }}</router-link>
        </v-form>
      </div>
      <div class="client-login">
        <h1><strong>{{ $t('message.fiducial_client') }}</strong></h1>

        <div class="btn-container">
          <button type="button" class="btn btn-connect" @click="loginAUT"><strong>{{
              $t('message.fiducial_login')
            }}</strong></button>
        </div>
        <div class="client-contact">
          <p><strong>{{ $t('message.client_contact') }}</strong></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import config from '../../config'

export default {
  name: 'Login',
  data: () => ({
    valid: false,
    showPassword: false,
    email: '',
    password: '',
    successMessage: null,
    sessionTimeout: false,
    autofill: false,
    intervalDetectAutofill: null,
    disableSubmit: false
  }),
  created () {
    this.$emit('update:layout', LoginLayout)

    if (this.$route.query.timeout === 'true') {
      this.sessionTimeout = true
    }

    if (this.$route.query.rejected === 'true') {
      this.$store.commit('setError', this.$t('message.account_blocked'))
    }

    if (this.$route.query.forgot === 'true') {
      this.successMessage = this.$t('message.password_reset_instructions')
    }

    document.title = config.title + ' - ' + this.$t('message.login')

    const tusElements = []

    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 3) === 'tus') {
        tusElements.push(localStorage.key(i))
      }
    }

    for (let i = 0; i < tusElements.length; i++) {
      localStorage.removeItem(tusElements[i])
    }
  },
  mounted () {
    // search for autofill every 100ms
    this.intervalDetectAutofill = setInterval(() => {
      if (document.querySelectorAll('input[type="password"]:-webkit-autofill').length > 0 ||
        document.querySelectorAll('input[type="email"]:-webkit-autofill').length > 0) {
        this.autofill = true
      }
    }, 100)

    // clean interval if needed after 3s
    setTimeout(() => {
      if (this.intervalDetectAutofill) {
        clearInterval(this.intervalDetectAutofill)
        this.intervalDetectAutofill = null
      }
    }, 3000)
  },
  computed: {
    error () {
      return this.$store.getters.getError
    }
  },
  watch: {
    password () {
      this.autofill = false
    },
    autofill () {
      // clean interval if autofill detected or user input
      if (this.intervalDetectAutofill) {
        clearInterval(this.intervalDetectAutofill)
        this.intervalDetectAutofill = null
      }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$store.commit('setLocale', locale)
      this.$i18n.locale = locale

      this.$router.go()
    },
    checkForm () {
      if (this.valid && !this.disableSubmit) {
        this.login()
      }
    },
    login () {
      const formData = {
        email: this.email.toLowerCase(),
        password: this.password
      }

      this.disableSubmit = true
      this.$store.dispatch('login', formData).then((e) => {
        if (e?.response?.status === 401) {
          this.disableSubmit = false
        }
      })
    },
    loginAUT () {
      location.href = config.autUrl +
        '?acr_values=' + config.acrValues +
        '&grant_type=' + config.grantType +
        '&client_id=' + config.clientId +
        '&redirect_uri=' + config.redirectUri +
        '&response_type=' + config.responseType +
        '&nonce=' + config.nonce +
        '&scope=' + config.scope
    }
  }
}
</script>
